import { useMemo } from 'react';
import { parse } from 'qs';
import { RobinsData, RobinsFile, RobinsQueryData, RobinsUser } from '../types/robins-types';
import { useInfiniteQuery } from '@tanstack/react-query';
import { QueryKeys } from '../store/queryKeys';
import { robinsDataQuery } from '../store/Robins/queries';
import { flatten, isNil, last } from 'lodash';
import { PaginationContextType } from '../components/PaginationContext/types';

interface UseRobinsDataReturn extends PaginationContextType {
  isLoading: boolean;
  robinsData: RobinsData;
}

const useRobinsData = (): UseRobinsDataReturn => {
  const searchStringData = useMemo(
    // because typings in @types/qs doesn't allow generics
    () => parse(window.location.search.slice(1)) as unknown as RobinsQueryData,
    []
  );

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    [QueryKeys.ROBINS_DATA, searchStringData],
    robinsDataQuery,
    {
      enabled: window.location.search?.length > 0,
      getNextPageParam: lastPage => {
        const currentPage = lastPage?.page ?? 0;
        const totalPages = !isNil(lastPage?.total_pages) ? lastPage?.total_pages - 1 : 0;

        if (currentPage < totalPages) {
          return currentPage + 1;
        }
      },
    }
  );

  const nextPageLoading = isFetchingNextPage;
  const pages = data?.pages;
  const allFiles = useMemo(() => flatten(pages?.map(p => p.files)) as RobinsFile[], [pages]);
  const allUsers = useMemo(() => flatten(pages?.map(p => p.users)) as RobinsUser[], [pages]);

  const robinsData: RobinsData = {
    ...(last(pages) as RobinsData),
    users: allUsers,
    files: allFiles,
  };

  return {
    isLoading,
    robinsData,
    fetchNextPage,
    hasNextPage: hasNextPage as boolean,
    nextPageLoading,
  };
};

export default useRobinsData;
