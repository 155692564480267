import LogRocket from 'logrocket';

export const initLogRocket = () => {
  if (process.env.NODE_ENV === 'production') {
    LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID as string, {
      dom: {
        textSanitizer: true,
        inputSanitizer: true,
      },
    });
  }
};
