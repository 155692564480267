import React, { Suspense, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './pages/Home/Home';
import useRobinsData from './hooks/useRobinsData';
import Spinner from './components/Spinner/Spinner';
import { Routes } from './constants';
import ExpiredPageScreen from './components/ExpiredPageScreen/ExpiredPageScreen';
import { PaginationContext } from './components/PaginationContext/PaginationContext';
import { PaginationContextType } from './components/PaginationContext/types';

const GoogleRevokeFiles = React.lazy(() => import('./pages/GoogleRevokeFiles/GoogleRevokeFiles'));
const OfficeRevokeFiles = React.lazy(() => import('./pages/OfficeRevokeFiles/OfficeRevokeFiles'));
const GoogleRevokeToken = React.lazy(() => import('./pages/GoogleRevokeToken/GoogleRevokeToken'));
const OfficeRevokeUser = React.lazy(() => import('./pages/OfficeRevokeUser/OfficeRevokeUser'));
const OfficeSuspendUser = React.lazy(() => import('./pages/OfficeSuspendUser/OfficeSuspendUser'));
const BoxRevokeFiles = React.lazy(() => import('./pages/BoxRevokeFiles/BoxRevokeFiles'));
const SlackRevokeFiles = React.lazy(() => import('./pages/SlackRevokeFiles/SlackRevokeFiles'));
const SlackViewFiles = React.lazy(() => import('./pages/SlackViewFiles/SlackViewFiles'));
const SlackDeleteFiles = React.lazy(() => import('./pages/SlackDeleteFiles/SlackDeleteFiles'));
const GoogleSuspendUser = React.lazy(() => import('./pages/GoogleSuspendUser/GoogleSuspendUser'));
const JustifyUsageScreen = React.lazy(() => import('./pages/JustifyUsage/JustifyUsageScreen'));

const Router = () => {
  const { robinsData, isLoading, hasNextPage, fetchNextPage, nextPageLoading } = useRobinsData();

  const contextValue: PaginationContextType = useMemo(
    () => ({ fetchNextPage, hasNextPage, nextPageLoading }),
    [fetchNextPage, hasNextPage, nextPageLoading]
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (robinsData?.isExpired) {
    return <ExpiredPageScreen robinsData={robinsData} />;
  }

  // TODO replace all these render props with context that will be used in all pages
  return (
    <Suspense fallback={<Spinner />}>
      <PaginationContext.Provider value={contextValue}>
        <Switch>
          <Route
            path={Routes.OFFICE}
            render={() => <OfficeRevokeFiles robinsData={robinsData} />}
          />
          <Route
            path={Routes.OFFICE_365}
            render={() => <OfficeRevokeFiles robinsData={robinsData} />}
          />
          <Route
            path={Routes.GOOGLE}
            render={() => <GoogleRevokeFiles robinsData={robinsData} />}
          />
          <Route path={Routes.BOX} render={() => <BoxRevokeFiles robinsData={robinsData} />} />
          <Route path={Routes.SLACK} render={() => <SlackDeleteFiles robinsData={robinsData} />} />
          <Route
            path={Routes.SLACK_VIEW_FILES}
            render={() => <SlackViewFiles robinsData={robinsData} />}
          />
          <Route
            path={Routes.SLACK_REVOKE_FILES}
            render={() => <SlackRevokeFiles robinsData={robinsData} />}
          />
          <Route
            path={Routes.GOOGLE_REVOKE_TOKEN}
            render={() => <GoogleRevokeToken robinsData={robinsData} />}
          />
          <Route
            path={Routes.OFFICE_REVOKE_USER}
            render={() => <OfficeRevokeUser robinsData={robinsData} />}
          />
          <Route
            path={Routes.OFFICE_SUSPEND_USER}
            render={() => <OfficeSuspendUser robinsData={robinsData} />}
          />
          <Route
            path={Routes.GOOGLE_REVOKE_USER}
            render={() => <GoogleSuspendUser robinsData={robinsData} />}
          />
          <Route
            path={Routes.GOOGLE_SUSPEND_USER}
            render={() => <GoogleSuspendUser robinsData={robinsData} />}
          />
          <Route
            path={Routes.JUSTIFY_APP_BY_MANAGER}
            render={() => <JustifyUsageScreen isByManager pageType={Routes.JUSTIFY_APP} robinsData={robinsData} />}
          />
          <Route
            path={Routes.JUSTIFY_USER_BY_MANAGER}
            render={() => <JustifyUsageScreen isByManager pageType={Routes.JUSTIFY_USER} robinsData={robinsData} />}
          />
          <Route
            path={Routes.JUSTIFY_FILE_BY_MANAGER}
            render={() => <JustifyUsageScreen isByManager pageType={Routes.JUSTIFY_FILE} robinsData={robinsData} />}
          />
          <Route
            path={Routes.JUSTIFY_APP}
            render={() => <JustifyUsageScreen pageType={Routes.JUSTIFY_APP} robinsData={robinsData} />}
          />
          <Route
            path={Routes.JUSTIFY_USER}
            render={() => <JustifyUsageScreen pageType={Routes.JUSTIFY_USER} robinsData={robinsData} />}
          />
          <Route
            path={Routes.JUSTIFY_FILE}
            render={() => <JustifyUsageScreen pageType={Routes.JUSTIFY_FILE} robinsData={robinsData} />}
          />
          <Route component={Home} />
        </Switch>
      </PaginationContext.Provider>
    </Suspense>
  );
};

export default Router;
