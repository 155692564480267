import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface Props {
  domain: string;
  height?: string;
  width?: string;
}

const CustomerIcon = ({ domain, width, height }: Props) => {
  const [logoSource, setLogoSource] = useState<string>('');

  useEffect(() => {
    setLogoSource(`https://logo.clearbit.com/${domain}`);
  }, [domain]);

  const handleLogoError = () => {
    setLogoSource('');
  };

  return (
    <Img width={width} height={height} src={logoSource} alt={domain} onError={handleLogoError} />
  );
};

export default CustomerIcon;

const Img = styled.img<{ width?: string; height?: string }>`
  width: ${({ width }) => width ?? 'min-content'};
  ${({ height }) => height && `height: ${height}`};
`;
