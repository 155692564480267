import React, { useEffect } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import CustomerIcon from '../CustomerIcon/CustomerIcon';

interface Props {
  title: string;
  customerDomain: string;
}

const RevokeScreenTitle = ({ title, customerDomain }: Props) => {
  useEffect(() => {
    document.title = `${title} - Wing Security`;
  }, [title]);

  return (
    <Root>
      <LeftSide>
        <ImageLogo src={`${process.env.PUBLIC_URL}/img/logo.svg`} />
        <Title>{title}</Title>
      </LeftSide>
      {!isEmpty(customerDomain) && (
        <RightSide>
          <CustomerIcon height="56px" domain={customerDomain} />
        </RightSide>
      )}
    </Root>
  );
};

export default RevokeScreenTitle;

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  border-bottom: 1px solid #ccc;
  height: 80px;
`;

const Side = styled.div`
  display: flex;
  align-items: center;
`;

const LeftSide = styled(Side)``;

const RightSide = styled(Side)``;

const ImageLogo = styled.img`
  height: 37px;
  width: 40px;
  object-fit: none;
`;

const Title = styled.div`
  margin-left: 16px;
  font-family: Inter-Medium;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
`;
