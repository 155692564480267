import { QueryFunction } from '@tanstack/react-query';
import { RobinsData, RobinsDataResponse, RobinsQueryData } from '../../types/robins-types';
import { QueryKeys } from '../queryKeys';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { PAGE_SIZE } from './constants';


export const robinsDataQuery: QueryFunction<RobinsData, [QueryKeys.ROBINS_DATA, RobinsQueryData]> =
  async ({ queryKey, signal, pageParam }) => {
    const [, searchQuery] = queryKey;
    const { hash, apiId } = searchQuery;

    const { data } = await axios.get<RobinsDataResponse>(
      `https://${apiId}.execute-api.us-east-1.amazonaws.com/prodapi/user-service/authfree/v1/robins/url`,
      { params: { page: pageParam, page_size: PAGE_SIZE, hash, }, signal }
    );

    const { data: robinsData, ...restData } = data ?? {};

    if (!isEmpty(robinsData)) {
      // We need files to be an array for the map to work.
      if (Array.isArray(robinsData?.files)) {
        robinsData.files = Object.values(robinsData.files);
      }

      // add version manually in case it was trimmed
      if (typeof robinsData.version === 'undefined') {
        robinsData.version = '3';
      }

      return {
        ...robinsData,
        ...restData,
        ...searchQuery,
      };
    }

    throw new Error('Something went wrong, please, refresh the page');
  };
