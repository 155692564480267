import { ThemeProvider } from '@material-ui/core/styles';
import { initMixpanel } from './services/mixpanel/mixpanel';
import { initLogRocket } from './services/logrocket/logrocket';
import Router from './Router';
import { theme } from './theme';
import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import queryClient from "./services/queryClient";

initLogRocket();
initMixpanel();

const App = () => (
  <BrowserRouter>
    <QueryParamProvider ReactRouterRoute={Route}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Router />
        </QueryClientProvider>
      </ThemeProvider>
    </QueryParamProvider>
  </BrowserRouter>
);

export default App;
