import React from 'react';
import RevokeScreenTitle from '../RevokeScreenTitle/RevokeScreenTitle';
import { Routes, SCREEN_TITLE_BY_PAGE } from '../../constants';
import expiredPageIllustration from '../../assets/illustrations/expiredPagelllustration.svg';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {RobinsPageProps} from "../../types/common-types";

const ExpiredPageScreen = ({ robinsData }: RobinsPageProps) => {
  const { customerDomain } = robinsData;
  const { pathname } = useLocation();
  const title = SCREEN_TITLE_BY_PAGE[pathname as Routes];

  return (
    <Root>
      <Content>
        <RevokeScreenTitle title={title} customerDomain={customerDomain} />
        <Body>
          <Image src={expiredPageIllustration} alt="Page is expired" />
          <h3>Oops... this page is expired</h3>
          <span>Please, contact your security team for more information</span>
        </Body>
      </Content>
    </Root>
  );
};

export default ExpiredPageScreen;

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  box-sizing: border-box;
`;

// TODO consolidate all these styles in single PageScreen (or any other name) component
const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 60%;
  border: 1px solid #dadce0;
  border-radius: 8px;
  margin: 0 auto;
  max-width: 1280px;
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Inter-Regular;
  padding: 0 24px 24px;

  h3 {
    font-family: Inter-SemiBold;
  }
`;

const Image = styled.img`
  width: 170px;
`;
