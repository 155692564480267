import React from 'react';
import styled, { keyframes } from 'styled-components';
import CircleImage from '../../assets/icons/loading/circle.png';

interface Props {
  className?: string;
}

const CircleSpinner: React.FC<Props> = ({ className }) => (
  <div className={className}>
    <Circle src={CircleImage} />
  </div>
);

export default CircleSpinner;

// https://styled-components.com/docs/basics#animations
const circleKeyframes = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Circle = styled.img`
  animation-name: ${circleKeyframes};
  animation-duration: 1400ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  height: 100%;
  width: 100%;
  z-index: 2;
`;
