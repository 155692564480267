export enum Routes {
  OFFICE = '/office',
  OFFICE_365 = '/office365',
  GOOGLE = '/google',
  BOX = '/box',
  SLACK = '/slack',
  SLACK_VIEW_FILES = '/slack-view',
  SLACK_REVOKE_FILES = '/slack-revoke',
  GOOGLE_REVOKE_TOKEN = '/revoke-token/google',
  OFFICE_REVOKE_USER = '/revoke-user/office365',
  OFFICE_SUSPEND_USER = '/suspend-user/office365',
  GOOGLE_REVOKE_USER = '/revoke-user/google',
  GOOGLE_SUSPEND_USER = '/suspend-user/google',
  JUSTIFY_APP = '/justify-app-usage',
  JUSTIFY_USER = '/justify-user-usage',
  JUSTIFY_FILE = '/justify-file-usage',
  JUSTIFY_APP_BY_MANAGER = '/justify-app-usage/manager',
  JUSTIFY_USER_BY_MANAGER = '/justify-user-usage/manager',
  JUSTIFY_FILE_BY_MANAGER = '/justify-file-usage/manager',
}

export const SCREEN_TITLE_BY_PAGE: Record<Routes, string> = {
  [Routes.OFFICE]: 'Revoke externally shared Microsoft OneDrive files',
  [Routes.OFFICE_365]: 'Revoke externally shared Microsoft OneDrive files',
  [Routes.GOOGLE]: 'Revoke externally shared Google Drive files',
  [Routes.BOX]: 'Revoke externally shared Box Drive files',
  [Routes.SLACK]: 'Delete Slack files',
  [Routes.SLACK_REVOKE_FILES]: 'Revoke externally shared Slack files',
  [Routes.SLACK_VIEW_FILES]: 'View Slack files',
  [Routes.GOOGLE_REVOKE_TOKEN]: 'Google - Revoke 3rd party access tokens',
  [Routes.OFFICE_REVOKE_USER]: 'Office365 - Revoke users',
  [Routes.OFFICE_SUSPEND_USER]: 'Office365 - Suspend users',
  [Routes.GOOGLE_REVOKE_USER]: 'Google - Revoke users',
  [Routes.GOOGLE_SUSPEND_USER]: 'Google - Suspend users',
  [Routes.JUSTIFY_APP]: 'Justify application usage',
  [Routes.JUSTIFY_USER]: 'Justify inactive account',
  [Routes.JUSTIFY_FILE]: 'Justify sensitive content',
  [Routes.JUSTIFY_APP_BY_MANAGER]: `Comment on employee's action`,
  [Routes.JUSTIFY_USER_BY_MANAGER]: `Comment on employee's action`,
  [Routes.JUSTIFY_FILE_BY_MANAGER]: `Comment on employee's action`,
};
