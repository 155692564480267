import mixpanel from 'mixpanel-browser';

export const initMixpanel = () => {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string, {
    loaded: mixpanel => {
      mixpanel.register({
        app: 'Robins2',
        node_env: process.env.NODE_ENV,
      });
    },
  });
};
