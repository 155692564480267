import React from 'react';
import styled from 'styled-components';
import Logo from '../../assets/icons/loading/logo.png';
import CircleSpinner from './CircleSpinner';

interface Props {
  size?: number;
  className?: string;
}

const Spinner = ({ size, className }: Props) => (
  <Container className={className} id="spinner">
    <SpinnerUnion size={size}>
      <CircleSpinner />
      <LogoImg src={Logo} />
    </SpinnerUnion>
  </Container>
);

export default Spinner;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  user-select: none;
`;

const SpinnerUnion = styled.div<{ size?: number }>`
  position: relative;
  height: ${({ size }) => size ?? 80}px;
  width: ${({ size }) => size ?? 80}px;
`;

const LogoImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 60%;
  width: 60%;
  z-index: 3;
`;
